import { useCurrentWorkspace } from '@atlasmic/shared/hooks/workspaces/useCurrentWorkspace'
import { defaultWorkspaceName } from '@atlasmic/shared/configs/workspaces/defaultWorkspaceName'

export const useCurrentWorkspaceName = (props) => {
  const { currentWorkspace, ...currentWorkspaceProps } = useCurrentWorkspace(props)

  return {
    currentWorkspaceName: currentWorkspace?.name || defaultWorkspaceName,
    ...currentWorkspaceProps
  }
}
