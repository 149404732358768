import React from 'react'
import { Box } from '~components/Box'

export const Group = (props) => (
  <Box
    borderTop='1px solid light-gray'
    paddingY={2}
    {...props}
  />
)
