import { Box } from '~components/Box'
import Link from 'next/link'
import { useCurrentWorkspace } from '@atlasmic/shared/hooks/workspaces/useCurrentWorkspace'
import { useCurrentWorkspaceName } from '~hooks/workspaces/useCurrentWorkspaceName'
import { WorkspaceAvatar } from '~components/workspaces/WorkspaceAvatar'

export const CurrentWorkspace = () => {
  const { currentWorkspace } = useCurrentWorkspace()
  const { currentWorkspaceName } = useCurrentWorkspaceName()

  return (
    <Box component={Link} href='/settings'>
      <a>
        <Box
          display='flex'
          alignItems='center'
          marginTop={1}
          paddingY={1}
          paddingX={1}
          marginBottom={-1}
          cursor='pointer'
          backgroundColor='light'
          hoverBackgroundColor='light-darken5'
          borderRadius='rounded'
        >
          <WorkspaceAvatar
            workspaceId={currentWorkspace?.id}
            height={4}
            width={4}
          />
          <Box paddingLeft={1}>
            <Box textStyle='button' color='dark'>
              {currentWorkspaceName}
            </Box>
          </Box>
        </Box>
      </a>
    </Box>
  )
}
