import { gql } from '@apollo/client'
import { ShopifyAppConfigFragment } from '~graphql/fragments/shopifyAppConfigs/ShopifyAppConfigFragment'

export const ShopifyAppConfigQuery = gql`
  query ShopifyAppConfigQuery($workspaceId: ID!) {
    shopifyAppConfig(workspaceId: $workspaceId) {
      ...ShopifyAppConfigFragment
    }
  }

  ${ShopifyAppConfigFragment}
`
