import React from 'react'
import { BaseLayout } from '~components/layouts/BaseLayout'
import { MobileOnboardingLayout } from '~components/layouts/MobileOnboardingLayout'
import { Box } from '~components/Box'
import { useEnsureCurrentWorkspace } from '~hooks/workspaces/useEnsureCurrentWorkspace'
import { Menu } from './Menu'

export const AuthenticatedLayout = ({ type, ...rest }) => {
  useEnsureCurrentWorkspace()

  return (
    <MobileOnboardingLayout>
      <BaseLayout>
        <Box display='flex' backgroundColor='light-primary' minHeight='100vh'>
          <Menu type={type} />
          <Box flexGrow={1} {...rest} />
        </Box>
      </BaseLayout>
    </MobileOnboardingLayout>
  )
}
