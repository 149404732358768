import { gql } from '@apollo/client'
import { WorkspaceSubscriptionFragment } from '~graphql/fragments/workspaceSubscriptions/WorkspaceSubscriptionFragment'

export const WorkspaceSubscriptionQuery = gql`
  query WorkspaceSubscriptionQuery($workspaceId: ID!) {
    workspaceSubscription(workspaceId: $workspaceId) {
      __typename

      ... on WorkspaceSubscription {
        ...WorkspaceSubscriptionFragment
      }
    }
  }

  ${WorkspaceSubscriptionFragment}
`
