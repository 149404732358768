import { gql } from '@apollo/client'

export const WorkspaceSubscriptionFragment = gql`
  fragment WorkspaceSubscriptionFragment on WorkspaceSubscription {
    id
    type
    status
    currentPeriodStart
    currentPeriodEnd
    customPlanSeatsCount
    trialEnd
    seatsCount
    hasPaymentMethod
    planId
    createdAt
  }
`
