import _ from 'lodash'
import { useQuery } from '@atlasmic/shared/hooks/misc/useQuery'
import { DomainVerificationsQuery } from 'graphql/queries/domainVerifications/DomainVerificationsQuery'

export const useDomainVerifications = (props) => {
  const queryProps = useQuery(DomainVerificationsQuery, props)

  return {
    domainVerifications: _.map(
      _.get(queryProps, 'data.domainVerifications.edges', []),
      'node'
    ),
    ...queryProps,
  }
}
