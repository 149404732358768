import { addCircle } from '@atlasmic/icons'
import { useModal } from '~hooks/modals/useModal'
import { Box } from '~components/Box'
import { ConfirmModal } from './ConfirmModal'

export const NewWorkspace = ({ setIsShowing }) => {
  const { setModal } = useModal()

  return (
    <Box borderBottom='separator' paddingY={1}>
      <Box
        display='flex'
        alignItems='center'
        padding={1}
        cursor='pointer'
        hoverBackgroundColor='light-darken5'
        borderRadius='rounded'
        onClick={() => {
          setModal(<ConfirmModal />)
          setIsShowing(false)
        }}
      >
        <Box component={addCircle} fill='dark' height={3} width={3} />
        <Box textStyle='text' color='dark' paddingLeft={1}>
          Add new workspace
        </Box>
      </Box>
    </Box>
  )
}
