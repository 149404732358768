import { useQuery } from '@atlasmic/shared/hooks/misc/useQuery'
import { WorkspaceSubscriptionQuery } from '~graphql/queries/workspaceSubscriptions/WorkspaceSubscriptionQuery'
import { useWorkspaceSubscriptionUpdated } from '~hooks/workspaceSubscriptions/useWorkspaceSubscriptionUpdated'
import { workspaceSubscription } from './lib/workspaceSubscription'

export const useWorkspaceSubscription = (props) => {
  const queryProps = useQuery(WorkspaceSubscriptionQuery, props)
  useWorkspaceSubscriptionUpdated(props)

  return {
    ...queryProps,
    workspaceSubscription: workspaceSubscription({ queryProps }),
  }
}
