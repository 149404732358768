import Link from 'next/link'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Button } from '@atlasmic/shared/components/buttons/Button'
import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { onError } from '~utils/errors/onError'
import { useCurrentWorkspaceSubscription } from '~hooks/workspaceSubscriptions/useCurrentWorkspaceSubscription'
import { useCurrentWorkspaceSubscriptionHasPaymentMethod } from '~hooks/workspaceSubscriptions/useCurrentWorkspaceSubscriptionHasPaymentMethod'
import { Box } from '~components/Box'
import { Group } from '../Group'

dayjs.extend(relativeTime)

export const TrialBillingMissing = () => {
  const { addToast } = useToast()

  const { currentWorkspaceSubscriptionHasPaymentMethod } =
    useCurrentWorkspaceSubscriptionHasPaymentMethod({
      onError: onError({ addToast }),
    })

  const { currentWorkspaceSubscription, loading, error } =
    useCurrentWorkspaceSubscription({
      onError: onError({ addToast }),
    })

  if (currentWorkspaceSubscription?.status !== 'trialing') return null
  if (currentWorkspaceSubscriptionHasPaymentMethod) return null

  return (
    <Group>
      <Box textStyle='widget-text' color='dark'>
        {dayjs().to(dayjs(currentWorkspaceSubscription.trialEnd), true)} left of
        your trial.
      </Box>
      <Link href='/settings'>
        <a>
          <Box
            color='primary'
            textStyle='widget-text'
            hoverTextDecoration='underline'
          >
            Set up billing
          </Box>
        </a>
      </Link>
    </Group>
  )
}
