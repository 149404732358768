import _ from 'lodash'
import { useIsDomainSetup } from '~hooks/setups/useIsDomainSetup'
import { useIsShopifyScriptTagsInstalled } from '~hooks/setups/useIsShopifyScriptTagsInstalled'

export const useIsAnyWebsiteInstalled = (props) => {
  const isDomainSetupProps = useIsDomainSetup(props)
  const isShopifyScriptTagsInstalledProps =
    useIsShopifyScriptTagsInstalled(props)

  return {
    isAnyWebsiteInstalled:
      isDomainSetupProps.isDomainSetup ||
      isShopifyScriptTagsInstalledProps.isShopifyScriptTagsInstalled,
    loading: _.some(
      [isDomainSetupProps, isShopifyScriptTagsInstalledProps],
      'loading'
    ),
    error: _.some(
      [isDomainSetupProps, isShopifyScriptTagsInstalledProps],
      'error'
    ),
    isDomainSetupProps,
    isShopifyScriptTagsInstalledProps,
  }
}
