import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { onboardingContact } from '@atlasmic/icons/illustrations'
import { MediumButton } from '@atlasmic/shared/components/buttons/MediumButton'
import { onError } from '~utils/errors/onError'
import { Box } from '~components/Box'
import { useUpsertApp } from '~hooks/apps/useUpsertApp'
import { MobileOnboardingBase } from '../MobileOnboardingBase'

export const EnableContactApp = () => {
  const { addToast } = useToast()

  const {
    upsertApp,
    mutationProps: { loading },
  } = useUpsertApp({
    variables: {
      type: 'CONTACT',
      input: {
        isEnabled: true,
      },
    },
    onError: onError({ addToast }),
  })

  return (
    <MobileOnboardingBase
      illustration={onboardingContact}
      title='Almost there!'
    >
      <Box component='h3' textStyle='text' color='dark' textAlign='center'>
        We’re glad to have you on board. Atlasmic platform was successfully
        installed into your Shopify store. You can now make the first app
        visible on your website - Contact form.
      </Box>

      <Box display='flex' paddingTop={2}>
        <MediumButton
          flexGrow={1}
          backgroundColor={loading ? 'dark-gray' : undefined}
          hoverBackgroundColor={loading ? 'dark-gray' : 'primary-darken5'}
          onClick={loading ? undefined : upsertApp}
        >
          {loading ? 'Enabling...' : 'Enable Contact App'}
        </MediumButton>
      </Box>

      <Box display='flex' paddingTop={2}>
        <MediumButton
          component='a'
          href={process.env.NEXT_PUBLIC_DOWNLOAD_PAGE_URL}
          flexGrow={1}
          backgroundColor='transparent'
          color='primary'
          border='inset 1px solid primary'
        >
          Download Mobile app
        </MediumButton>
      </Box>
    </MobileOnboardingBase>
  )
}
