import Link from 'next/link'
import { Box } from '~components/Box'
import { Skeleton } from '@atlasmic/shared/components/skeletons/misc/Skeleton'

export const CurrentPlan = ({
  currentPlanProps: { currentPlan, loading, error },
}) => {
  if ((loading || error) && !currentPlan) {
    return <Skeleton textStyle='text' width={10} />
  }

  return (
    <Box textStyle='text' color='dark'>
      Current plan: {currentPlan.name}
    </Box>
  )
}
