import _ from 'lodash'
import { useCurrentWorkspace } from '@atlasmic/shared/hooks/workspaces/useCurrentWorkspace'
import { useWorkspaceSubscription } from '~hooks/workspaceSubscriptions/useWorkspaceSubscription'

export const useCurrentWorkspaceSubscription = (
  { variables, onError, skip, ...rest } = { variables: {} }
) => {
  const currentWorkspaceProps = useCurrentWorkspace({
    onError,
  })

  const queryProps = useWorkspaceSubscription({
    variables: {
      workspaceId: currentWorkspaceProps.currentWorkspace?.id,
      ...variables,
    },
    skip: skip || !currentWorkspaceProps.currentWorkspace?.id,
    onError,
    ...rest,
  })

  return {
    ...queryProps,
    currentWorkspaceProps,
    currentWorkspaceSubscription: queryProps.workspaceSubscription,
    loading: _.some([currentWorkspaceProps, queryProps], 'loading'),
    error: _.some([currentWorkspaceProps, queryProps], 'error'),
  }
}
