import _ from 'lodash'
import { useShopifyScriptTags } from '~hooks/shopify/useShopifyScriptTags'

export const useIsShopifyScriptTagsInstalled = (props) => {
  const shopifyScriptTagsProps = useShopifyScriptTags(props)

  return {
    ...shopifyScriptTagsProps,
    isShopifyScriptTagsInstalled: !_.isEmpty(
      shopifyScriptTagsProps.shopifyScriptTags
    ),
  }
}
