import { Box } from '~components/Box'
import { MobileOnboarding } from './MobileOnboarding'

export const MobileOnboardingLayout = ({ children }) => (
  <>
    <Box display={{ xs: 'none', md: 'block' }}>{children}</Box>
    <Box display={{ xs: 'block', md: 'none' }}>
      <MobileOnboarding />
    </Box>
  </>
)
