import { useMutation } from '@apollo/client'
import { useCurrentWorkspace } from '@atlasmic/shared/hooks/workspaces/useCurrentWorkspace'
import { UpsertAppMutation } from 'graphql/mutations/apps/UpsertAppMutation'
import { update } from './lib/update'

export const useUpsertApp = ({
  variables,
  ...rest
}) => {
  const { currentWorkspace, ...currentWorkspaceQueryProps } = useCurrentWorkspace()
  const [upsertApp, mutationProps] = useMutation(UpsertAppMutation, {
    variables: {
      workspaceId: currentWorkspace?.id,
      ...variables,
    },
    skip: currentWorkspace?.id,
    update,
    ...rest
  })

  return {
    upsertApp,
    mutationProps,
  }
}
