import _ from 'lodash'
import { useDomainVerifications } from '~hooks/domainVerifications/useDomainVerifications'

export const useLatestDomainVerification = ({
  variables,
  ...rest
} = { variables: {} }) => {
  const { domainVerifications, loading, error } = useDomainVerifications({
    variables: {
      first: 1,
      ...variables,
    },
    ...rest
  })

  return {
    latestDomainVerification: _.first(domainVerifications),
    loading,
    error,
  }
}
