import { Box } from '~components/Box'

export const ModalTitle = (props) => (
  <Box
    display='flex'
    alignItems='center'
    justifyContent='center'
    textStyle='h3'
    color='dark'
    paddingTop={4}
    paddingBottom={2}
    textAlign='center'
    {...props}
  />
)
