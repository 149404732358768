import { useCurrentWorkspaceSubscription } from '~hooks/workspaceSubscriptions/useCurrentWorkspaceSubscription'
import { usePlan } from '~hooks/plans/usePlan'

export const useCurrentPlan = (
  { variables, onError, skip, ...rest } = { variables: {} }
) => {
  const currentWorkspaceSubscriptionProps = useCurrentWorkspaceSubscription({
    onError,
    skip,
    ...rest,
  })

  const planProps = usePlan({
    variables: {
      planId:
        currentWorkspaceSubscriptionProps.currentWorkspaceSubscription?.planId,
      ...variables,
    },
    skip:
      skip ||
      !currentWorkspaceSubscriptionProps.currentWorkspaceSubscription?.planId,
    onError,
    ...rest,
  })

  return {
    ...planProps,
    currentPlan: planProps.plan,
    loading: currentWorkspaceSubscriptionProps.loading || planProps.loading,
    error: currentWorkspaceSubscriptionProps.error || planProps.error,
    currentWorkspaceSubscriptionProps,
  }
}
