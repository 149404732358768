export const onClick = ({
  setCurrentWorkspaceId,
  workspace: {
    id,
  },
  client,
  router,
  setIsShowing,
}) => () => {
  setCurrentWorkspaceId(id)
  router.push('/')
  setIsShowing(false)
}
