import _ from 'lodash'
import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { onError } from '~utils/errors/onError'
import { Box } from '~components/Box'
import { useOtherWorkspaces } from '@atlasmic/shared/hooks/workspaces/useOtherWorkspaces'
import { WorkspacesSkeleton } from '~components/skeletons/workspaces/WorkspacesSkeleton'
import { Workspace } from './Workspace'
import { NewWorkspace } from './NewWorkspace'

export const Workspaces = ({ setIsShowing }) => {
  const { addToast } = useToast()

  const { otherWorkspaces, error } = useOtherWorkspaces({
    onError: onError({ addToast }),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  return (
    <Box>
      {error && _.isEmpty(otherWorkspaces) && <WorkspacesSkeleton />}
      {_.map(otherWorkspaces, (workspace) => (
        <Workspace
          key={workspace.id}
          workspace={workspace}
          setIsShowing={setIsShowing}
        />
      ))}
      <NewWorkspace setIsShowing={setIsShowing} />
    </Box>
  )
}
