import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { onboardingSuccess } from '@atlasmic/icons/illustrations'
import { MediumButton } from '@atlasmic/shared/components/buttons/MediumButton'
import { onError } from '~utils/errors/onError'
import { Box } from '~components/Box'
import { useUpsertApp } from '~hooks/apps/useUpsertApp'
import { MobileOnboardingBase } from '../MobileOnboardingBase'
import { OpenWebsite } from './OpenWebsite'

export const DisableContactApp = () => {
  const { addToast } = useToast()

  const {
    upsertApp,
    mutationProps: { loading },
  } = useUpsertApp({
    variables: {
      type: 'CONTACT',
      input: {
        isEnabled: false,
      },
    },
    onError: onError({ addToast }),
  })

  return (
    <MobileOnboardingBase illustration={onboardingSuccess} title="It's up!">
      <Box component='h3' textStyle='text' color='dark' textAlign='center'>
        The Contact form is now visible on your website. There's more things you
        can set up with the desktop or mobile versions of Atlasmic, but now open
        your website and check out how it looks.
      </Box>

      <Box display='flex' paddingTop={2}>
        <MediumButton
          flexGrow={1}
          color='danger'
          backgroundColor='danger-transparentize90'
          hoverBackgroundColor='danger-transparentize85'
          onClick={loading ? undefined : upsertApp}
        >
          {loading ? 'Disabling...' : 'Disable Contact App'}
        </MediumButton>
      </Box>

      <Box display='flex' paddingTop={2}>
        <MediumButton
          component='a'
          href={process.env.NEXT_PUBLIC_DOWNLOAD_PAGE_URL}
          flexGrow={1}
          backgroundColor='transparent'
          color='primary'
          border='inset 1px solid primary'
        >
          Download Mobile app
        </MediumButton>
      </Box>

      <OpenWebsite />
    </MobileOnboardingBase>
  )
}
