import { gql } from '@apollo/client'
import { DomainVerificationFragment } from '~graphql/fragments/domainVerifications/DomainVerificationFragment'

export const DomainVerificationsQuery = gql`
  query DomainVerificationsQuery($domainId: ID!, $first: Int, $cursor: String) {
    domainVerifications(domainId: $domainId, first: $first, after: $cursor) {
      edges {
        node {
          ...DomainVerificationFragment
        }
      }
    }
  }

  ${DomainVerificationFragment}
`
