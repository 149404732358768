import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { Button } from '@atlasmic/shared/components/buttons/Button'
import { onError } from '~utils/errors/onError'
import { ButtonSkeleton } from '~components/skeletons/misc/ButtonSkeleton'
import { Box } from '~components/Box'
import { useShopifyOauthUrl } from '~hooks/shopify/useShopifyOauthUrl'

export const ShopifyUnauthorized = () => {
  const { addToast } = useToast()
  const {
    shopifyOauthUrl,
    loading,
    error,
    shopifyAppConfigProps: { data },
  } = useShopifyOauthUrl({
    onError: onError({ addToast }),
  })

  return (
    <Box borderBottom='separator'>
      <Box marginY={1} padding={1}>
        <Box textStyle='widget-text' color='dark-gray' paddingBottom={2}>
          We could not authenticate with Shopify. Reconnect Shopify by clicking
          the button below.
        </Box>

        {(loading || error) && !data ? (
          <ButtonSkeleton />
        ) : (
          <Box component='a' href={shopifyOauthUrl}>
            <Button hoverBackgroundColor='primary-darken5'>
              Reconnect Shopify
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}
