import _ from 'lodash'

export const currentAgentFullName = ({
  currentAgent,
}) => {
  if (!currentAgent) return null
  if (_.isEmpty(currentAgent.firstName) && _.isEmpty(currentAgent.lastName)) return null

  return `${currentAgent.firstName} ${currentAgent.lastName}`
}
