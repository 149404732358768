import {
  convert,
  convertFilled,
} from '@atlasmic/icons'
import { useApp } from '@atlasmic/shared/hooks/apps/useApp'
import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { onError } from '~utils/errors/onError'
import { Item } from '../Item'

export const ConvertAppItem = ({
  type,
}) => {
  const { addToast } = useToast()
  const {
    app,
    loading,
    error,
  } = useApp({
    variables: {
      type: 'CONVERT',
    },
    onError: onError({ addToast }),
  })

  if (!app) return null
  if (!app.isEnabled) return null

  return (
    <Item
      href='/apps/convert/view'
      icon={convert}
      activeIcon={convertFilled}
      type={type}
    >
      Convert
    </Item>
  )
}
