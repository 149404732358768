import { Box } from '~components/Box'
import { CurrentWorkspace } from './CurrentWorkspace'
import { CurrentWorkspaceSubscriptionDetails } from './CurrentWorkspaceSubscriptionDetails'
import { Workspaces } from './Workspaces'
import { Item } from './Item'

export const Menu = ({ setIsShowing, type }) => (
  <Box
    position='absolute'
    top={1}
    left={0}
    minWidth={29}
    backgroundColor='light'
    paddingX={1}
    borderRadius='rounded'
    boxShadow='light'
    zIndex={9999}
  >
    {type === 'minimized' && <CurrentWorkspace />}
    <CurrentWorkspaceSubscriptionDetails />
    <Workspaces setIsShowing={setIsShowing} />
    <Item href='/profile'>Profile</Item>
    <Item href='/logout' borderBottom={0}>
      Logout
    </Item>
  </Box>
)
