import { useShopifyAppConfig } from '~hooks/shopifyAppConfigs/useShopifyAppConfig'
import { shopifyOauthUrl } from '~utils/shopify/shopifyOauthUrl'

export const useShopifyOauthUrl = (props) => {
  const shopifyAppConfigProps = useShopifyAppConfig(props)

  return {
    ...shopifyAppConfigProps,
    shopifyAppConfigProps,
    shopifyOauthUrl: shopifyOauthUrl({
      shopifyShop: shopifyAppConfigProps.shopifyAppConfig?.shop,
    }),
  }
}
