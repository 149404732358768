import { gql } from '@apollo/client'

export const PlanFragment = gql`
  fragment PlanFragment on Plan {
    id
    name
    slug
    basePriceUsd
    basePriceEur
    appTypes
    seatsCount
    peopleReachedCount
    peopleTrackedCount
  }
`
