import { useRouter } from 'next/router'
import { useApolloClient } from '@apollo/client'
import { Box } from '~components/Box'
import { defaultWorkspaceName } from '@atlasmic/shared/configs/workspaces/defaultWorkspaceName'
import { WorkspaceAvatar } from '~components/workspaces/WorkspaceAvatar'
import { useCurrentWorkspaceId } from '~hooks/workspaces/useCurrentWorkspaceId'
import { WorkspaceBase } from '~components/workspaces/WorkspaceBase'
import { onClick } from './lib/onClick'

export const Workspace = ({
  workspace: {
    id,
    name,
  },
  workspace,
  setIsShowing,
}) => {
  const router = useRouter()
  const client = useApolloClient()
  const { setCurrentWorkspaceId } = useCurrentWorkspaceId()

  return (
    <Box
      borderBottom='separator'
    >
      <Box
        hoverBackgroundColor='light-darken5'
        marginY={1}
        paddingX={1}
        borderRadius='rounded'
      >
        <WorkspaceBase
          onClick={onClick({ setCurrentWorkspaceId, workspace, client, router, setIsShowing })}
          cursor='pointer'
        >
          <WorkspaceAvatar
            workspaceId={id}
            height={4}
            width={4}
          />
          <Box
            textStyle='widget-text'
            color='dark'
            marginLeft={1}
          >
            {name || defaultWorkspaceName}
          </Box>
        </WorkspaceBase>
      </Box>
    </Box>
  )
}
