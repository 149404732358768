import { useCurrentWorkspaceSubscription } from '~hooks/workspaceSubscriptions/useCurrentWorkspaceSubscription'

export const useCurrentWorkspaceSubscriptionHasPaymentMethod = (props) => {
  const currentWorkspaceSubscriptionProps = useCurrentWorkspaceSubscription(props)

  return {
    currentWorkspaceSubscriptionHasPaymentMethod: currentWorkspaceSubscriptionProps.currentWorkspaceSubscription?.hasPaymentMethod,
    ...currentWorkspaceSubscriptionProps,
  }
}
