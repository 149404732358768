import Link from 'next/link'
import {
  unavailableOnMobile,
  onboardingContact,
} from '@atlasmic/icons/illustrations'
import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { onError } from '~utils/errors/onError'
import { useApp } from '@atlasmic/shared/hooks/apps/useApp'
import { BiggerButton } from '@atlasmic/shared/components/buttons/BiggerButton'
import { useIsAnyWebsiteInstalled } from '~hooks/setups/useIsAnyWebsiteInstalled'
import { AuthenticatedFocusLoading } from '~components/AuthenticatedFocusLoading'
import { Box } from '~components/Box'
import { ContinueOnDesktop } from './ContinueOnDesktop'
import { EnableContactApp } from './EnableContactApp'
import { DisableContactApp } from './DisableContactApp'

export const MobileOnboarding = () => {
  const { addToast } = useToast()
  const isAnyWebsiteInstalledProps = useIsAnyWebsiteInstalled({
    onError: onError({ addToast }),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const appProps = useApp({
    variables: {
      type: 'CONTACT',
    },
    onError: onError({ addToast }),
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  if (isAnyWebsiteInstalledProps.loading || isAnyWebsiteInstalledProps.error) {
    return (
      <AuthenticatedFocusLoading
        title='Opening your workspace'
        description='Checking configuration'
      />
    )
  }

  if (!isAnyWebsiteInstalledProps.isAnyWebsiteInstalled) {
    return <ContinueOnDesktop />
  }

  if ((appProps.loading || appProps.error) && !appProps.data) {
    return (
      <AuthenticatedFocusLoading
        title='Opening your workspace'
        description='Checking configuration'
      />
    )
  }

  if (!appProps.app?.isEnabled) {
    return <EnableContactApp />
  }

  return <DisableContactApp />
}
