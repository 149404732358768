import Link from 'next/link'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Box } from '~components/Box'
import { Skeleton } from '@atlasmic/shared/components/skeletons/misc/Skeleton'
dayjs.extend(relativeTime)

export const CurrentWorkspaceSubscription = ({
  currentPlanProps: {
    currentWorkspaceSubscriptionProps: {
      currentWorkspaceSubscription,
      loading,
      error,
    },
  },
}) => {
  if ((loading || error) && !currentWorkspaceSubscription) {
    return <Skeleton textStyle='widget-text' width={20} />
  }

  const { status, trialEnd, seatsCount } = currentWorkspaceSubscription

  if (status === 'trialing') {
    return (
      <Box textStyle='widget-text' color='dark-gray'>
        Trial will end {dayjs().to(dayjs(trialEnd))}
      </Box>
    )
  }

  return (
    <Box textStyle='widget-text' color='dark-gray'>
      {seatsCount} team members
    </Box>
  )
}
