import React from 'react'
import { Box } from '~components/Box'
import {
  addCircle,
  addCircleFilled,
  home,
  homeFilled,
  people,
  peopleFilled,
  conversations,
  conversationsFilled,
  allApps,
  allAppsFilled,
  settings,
  settingsFilled,
  downloadCircle,
  downloadCircleFilled,
} from '@atlasmic/icons'
import { Group } from './Group'
import { Workspace } from './Workspace'
import { Item } from './Item'
import { ConvertAppItem } from './ConvertAppItem'
import { TrialBillingMissing } from './TrialBillingMissing'
import { UpgradeGroup } from './UpgradeGroup'

export const Menu = ({ type }) => (
  <Box margin={3} width={type === 'minimized' ? 'inherit' : 29} flexShrink={0}>
    <Workspace type={type} />

    <Box paddingY={3}>
      <Group>
        <Item
          href='/'
          match={/^\/$/}
          icon={home}
          activeIcon={homeFilled}
          type={type}
        >
          Dashboard
        </Item>
        <Item
          href='/conversations'
          icon={conversations}
          activeIcon={conversationsFilled}
          type={type}
        >
          Conversations
        </Item>
        <Item
          href='/people'
          icon={people}
          activeIcon={peopleFilled}
          type={type}
        >
          People
        </Item>
      </Group>

      <Group>
        <Item
          href='/apps'
          match={/(\/apps\/([^\/]+)$)|\/apps$/}
          icon={allApps}
          activeIcon={allAppsFilled}
          type={type}
        >
          Apps
        </Item>
        <ConvertAppItem type={type} />
      </Group>

      <Group>
        <Item
          href='/settings'
          icon={settings}
          activeIcon={settingsFilled}
          type={type}
        >
          Settings
        </Item>
        {false && (
          <Item
            href='/download'
            icon={downloadCircle}
            activeIcon={downloadCircleFilled}
            type={type}
          >
            Download Atlasmic
          </Item>
        )}
        <Item
          href='/install'
          icon={addCircle}
          activeIcon={addCircleFilled}
          type={type}
        >
          Install Atlasmic
        </Item>
      </Group>

      <UpgradeGroup type={type} />

      {type !== 'minimized' && <TrialBillingMissing />}
    </Box>
  </Box>
)
