import _ from 'lodash'
import { useIsAnyDomainVerificationSuccessful } from '~hooks/domainVerifications/useIsAnyDomainVerificationSuccessful'
import { useIsAnyDomainVerificationPending } from '~hooks/domainVerifications/useIsAnyDomainVerificationPending'

export const useIsDomainSetup = (props) => {
  const isAnyDomainVerificationSuccessfulProps =
    useIsAnyDomainVerificationSuccessful(props)
  const isAnyDomainVerificationPendingProps =
    useIsAnyDomainVerificationPending(props)

  return {
    isDomainSetup:
      isAnyDomainVerificationSuccessfulProps.isAnyDomainVerificationSuccessful ||
      isAnyDomainVerificationPendingProps.isAnyDomainVerificationPending,
    loading: _.some(
      [
        isAnyDomainVerificationSuccessfulProps,
        isAnyDomainVerificationPendingProps,
      ],
      'loading'
    ),
    error: _.some(
      [
        isAnyDomainVerificationSuccessfulProps,
        isAnyDomainVerificationPendingProps,
      ],
      'error'
    ),
    isAnyDomainVerificationSuccessfulProps,
    isAnyDomainVerificationPendingProps,
  }
}
