import { gql } from '@apollo/client'

export const DomainVerificationFragment = gql`
  fragment DomainVerificationFragment on DomainVerification {
    id
    status
    writeKey
    createdAt
  }
`
