import Image from 'next/image'
import _ from 'lodash'
import { wavingHand } from '@atlasmic/icons'
import { workspaceBackgroundColor } from '@atlasmic/shared/utils/workspaces/workspaceBackgroundColor'
import { Box } from '~components/Box'
import { useWorkspace } from '@atlasmic/shared/hooks/workspaces/useWorkspace'
import { defaultWorkspaceName } from '@atlasmic/shared/configs/workspaces/defaultWorkspaceName'

export const WorkspaceAvatar = ({
  workspaceId,
  height = 6,
  width = 6,
  borderRadius = 'lightly-rounded',
  ...rest
}) => {
  const { workspace } = useWorkspace({
    variables: {
      workspaceId,
    },
    skip: !workspaceId,
  })

  const avatarUrl = workspace?.avatar?.url

  return (
    <Box
      backgroundColor={workspaceBackgroundColor({ workspace })}
      borderRadius={borderRadius}
      height={height}
      width={width}
      display='flex'
      alignItems='center'
      justifyContent='center'
      overflow='hidden'
      {...rest}
    >
      {avatarUrl ? (
        <Image
          key={avatarUrl}
          src={avatarUrl}
          height={200}
          width={200}
        />
      ) : (
        <Box
          component={wavingHand}
        />
      )}
    </Box>
  )
}
