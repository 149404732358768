import _ from 'lodash'
import { useLatestDomainVerification } from '~hooks/domainVerifications/useLatestDomainVerification'
import { useDomains } from '~hooks/domains/useDomains'

export const useIsAnyDomainVerificationPending = (props) => {
  const {
    domains,
    ...domainProps
  } = useDomains(props)

  // TODO implement properly
  const domainId = _.first(domains)?.id

  const { latestDomainVerification, ...latestDomainVerificationProps } = useLatestDomainVerification({
    variables: {
      domainId,
    },
    skip: !domainId,
    ...props
  })

  return {
    isAnyDomainVerificationPending: domainId && !latestDomainVerification,
    loading: _.some([domainProps, latestDomainVerificationProps], 'loading'),
    error: _.some([domainProps, latestDomainVerificationProps], 'error'),
  }
}
