import _ from 'lodash'
import { useQuery } from '@atlasmic/shared/hooks/misc/useQuery'
import { PlanQuery } from '~graphql/queries/plans/PlanQuery'

export const usePlan = (props) => {
  const queryProps = useQuery(PlanQuery, props)

  return {
    plan: _.get(queryProps, 'data.plan', null),
    ...queryProps,
  }
}
