import { useCurrentAgent } from '@atlasmic/shared/hooks/agents/useCurrentAgent'
import { currentAgentFullName } from './lib/currentAgentFullName'

export const useCurrentAgentFullName = (props) => {
  const { currentAgent, ...currentAgentProps } = useCurrentAgent(props)

  return {
    currentAgentFullName: currentAgentFullName({ currentAgent }),
    ...currentAgentProps,
  }
}
