import { unavailableOnMobile } from '@atlasmic/icons/illustrations'
import { BiggerButton } from '@atlasmic/shared/components/buttons/BiggerButton'
import { Box } from '~components/Box'
import { MobileOnboardingBase } from '../MobileOnboardingBase'

export const ContinueOnDesktop = () => (
  <MobileOnboardingBase
    illustration={unavailableOnMobile}
    variant='dark'
    title='Signed up successfully!'
  >
    <Box component='h3' textStyle='text' color='light' textAlign='center'>
      But there's one more thing. Use a desktop browser to sign in to{' '}
      <Box
        component='a'
        href={process.env.NEXT_PUBLIC_LANDING_URL}
        display='inline'
        cursor='pointer'
        color='primary'
        hoverColor='primary-darken5'
        textDecoration='underline'
        target='_blank'
        rel='noopener'
      >
        atlasmic.com
      </Box>{' '}
      to finish the setup.
    </Box>

    <Box marginTop={3}>
      <Box textStyle='text' color='light' textAlign='center'>
        If you don't have access to a desktop browser now, you can explore our
        mobile app in the meantime.
      </Box>
      <Box display='flex' paddingTop={2}>
        <BiggerButton
          component='a'
          href={process.env.NEXT_PUBLIC_DOWNLOAD_PAGE_URL}
          flexGrow={1}
          hoverBackgroundColor='primary-darken5'
        >
          Download app
        </BiggerButton>
      </Box>
    </Box>
  </MobileOnboardingBase>
)
