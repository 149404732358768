import _ from 'lodash'
import Link from 'next/link'
import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { onError } from '~utils/errors/onError'
import { useCurrentPlan } from '~hooks/plans/useCurrentPlan'
import { Box } from '~components/Box'
import { shopifyUnauthorizedTypenames } from '~utils/workspaceSubscriptions/shopifyUnauthorizedTypenames'
import { CurrentPlan } from './CurrentPlan'
import { CurrentWorkspaceSubscription } from './CurrentWorkspaceSubscription'
import { ShopifyUnauthorized } from './ShopifyUnauthorized'

export const CurrentWorkspaceSubscriptionDetails = () => {
  const { addToast } = useToast()
  const currentPlanProps = useCurrentPlan({
    onError: onError({ addToast }),
  })

  if (
    _.includes(
      shopifyUnauthorizedTypenames,
      currentPlanProps.currentWorkspaceSubscriptionProps.data
        ?.workspaceSubscription?.__typename
    )
  ) {
    return <ShopifyUnauthorized />
  }

  return (
    <Box component={Link} href='/settings'>
      <a>
        <Box borderBottom='separator'>
          <Box
            cursor='pointer'
            marginY={1}
            padding={1}
            hoverBackgroundColor='light-darken5'
            borderRadius='rounded'
          >
            <CurrentPlan currentPlanProps={currentPlanProps} />
            <CurrentWorkspaceSubscription currentPlanProps={currentPlanProps} />
          </Box>
        </Box>
      </a>
    </Box>
  )
}
