import { useState } from 'react'
import { atlasmicRectangle } from '@atlasmic/icons'
import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { defaultCurrentAgentFullName } from '~configs/agents/currentAgent'
import { onError } from '~utils/errors/onError'
import { Box } from '~components/Box'
import { Skeleton } from '@atlasmic/shared/components/skeletons/misc/Skeleton'
import { useCurrentAgent } from '@atlasmic/shared/hooks/agents/useCurrentAgent'
import { useCurrentAgentFullName } from '~hooks/agents/useCurrentAgentFullName'
import { useCurrentWorkspace } from '@atlasmic/shared/hooks/workspaces/useCurrentWorkspace'
import { WorkspaceAvatar } from '~components/workspaces/WorkspaceAvatar'
import { defaultWorkspaceName } from '@atlasmic/shared/configs/workspaces/defaultWorkspaceName'
import { Menu } from './Menu'

export const Workspace = ({ type }) => {
  const { addToast } = useToast()
  const [isShowing, setIsShowing] = useState(false)

  const { currentAgent } = useCurrentAgent()
  const { currentWorkspace, loading, error } = useCurrentWorkspace({
    onError: onError({ addToast }),
  })

  const { currentAgentFullName, ...currentAgentFullNameProps } =
    useCurrentAgentFullName({
      onError: onError({ addToast }),
    })

  return (
    <Box>
      <Box
        cursor='pointer'
        display='flex'
        alignItems='center'
        marginBottom={type === 'minimized' ? 1 : 0}
        onClick={() => setIsShowing((prev) => !prev)}
        opacity={isShowing ? 0.5 : undefined}
        hoverOpacity={0.5}
      >
        <WorkspaceAvatar
          workspaceId={currentWorkspace?.id}
          width={type === 'minimized' ? 5 : 6}
          height={type === 'minimized' ? 5 : 6}
          flexShrink={0}
        />
        {type !== 'minimized' && (
          <Box paddingLeft={2}>
            <Box textStyle='button' color='dark'>
              {!currentWorkspace ? (
                <Skeleton textStyle='button' width={15} />
              ) : (
                currentWorkspace.name || defaultWorkspaceName
              )}
            </Box>
            <Box textStyle='text' color='dark'>
              {!currentAgent || currentAgentFullNameProps.loading ? (
                <Skeleton textStyle='text' width={10} />
              ) : (
                currentAgentFullName || defaultCurrentAgentFullName
              )}
            </Box>
          </Box>
        )}
      </Box>
      {isShowing && (
        <Box
          position='fixed'
          top={0}
          right={0}
          bottom={0}
          left={0}
          onClick={() => setIsShowing(false)}
        />
      )}
      <Box position='relative'>
        {isShowing && <Menu setIsShowing={setIsShowing} type={type} />}
      </Box>
    </Box>
  )
}
