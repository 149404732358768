import _ from 'lodash'
import { MediumButton } from '@atlasmic/shared/components/buttons/MediumButton'
import { useDomains } from '~hooks/domains/useDomains'
import { Box } from '~components/Box'

export const OpenWebsite = () => {
  const { domains, loading, error } = useDomains({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const lastDomain = _.last(domains)
  if (!lastDomain) return null

  return (
    <Box display='flex' justifyContent='center' paddingTop={3}>
      <Box
        component='a'
        target='_blank'
        href={`https://${lastDomain.host}`}
        rel='noopener'
        textStyle='button'
        color='primary'
        textAlign='center'
      >
        Open {lastDomain.host}
      </Box>
    </Box>
  )
}
