import { Box } from '~components/Box'

export const ModalDescription = (props) => (
  <Box
    textStyle='text'
    color='dark'
    paddingBottom={4}
    textAlign='center'
    {...props}
  />
)
