import Link from 'next/link'
import { multipleWebsites } from '@atlasmic/icons'
import { Box } from '~components/Box'
import { Button } from '@atlasmic/shared/components/buttons/Button'
import { MediumButton } from '@atlasmic/shared/components/buttons/MediumButton'
import { useModal } from '~hooks/modals/useModal'
import { Modal } from '~components/modals/Modal'
import { ModalHeader } from '~components/modals/ModalHeader'
import { ModalTitle } from '~components/modals/ModalTitle'
import { ModalDescription } from '~components/modals/ModalDescription'
import { ModalButtonsContainer } from '~components/modals/ModalButtonsContainer'

export const ConfirmModal = () => {
  const { setModal } = useModal()

  return (
    <Modal maxWidth={64}>
      <ModalHeader>
        <Box component={multipleWebsites} />
      </ModalHeader>
      <ModalTitle>Do you want to add Atlasmic to multiple websites?</ModalTitle>
      <ModalDescription>
        Maybe you don’t need to add additional workspace. You can add Atlasmic
        to unlimited websites on the same plan.
      </ModalDescription>
      <ModalButtonsContainer>
        <Link href='/workspaces/create'>
          <a>
            <Button
              backgroundColor='light'
              color='primary'
              hoverColor='primary-darken5'
              marginRight={2}
              onClick={() => setModal(null)}
            >
              Add new workspace
            </Button>
          </a>
        </Link>
        <Link href='/install'>
          <a>
            <MediumButton
              hoverBackgroundColor='primary-darken5'
              onClick={() => setModal(null)}
            >
              Add multiple websites
            </MediumButton>
          </a>
        </Link>
      </ModalButtonsContainer>
    </Modal>
  )
}
