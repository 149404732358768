import _ from 'lodash'
import { useQuery } from '@atlasmic/shared/hooks/misc/useQuery'
import { useCurrentWorkspace } from '@atlasmic/shared/hooks/workspaces/useCurrentWorkspace'
import { ShopifyAppConfigQuery } from '~graphql/queries/shopifyAppConfigs/ShopifyAppConfigQuery'

export const useShopifyAppConfig = (
  { variables = {}, ...rest } = { variables: {} }
) => {
  const currentWorkspaceProps = useCurrentWorkspace({
    skip: variables.workspaceId,
  })

  const queryProps = useQuery(ShopifyAppConfigQuery, {
    variables: {
      ...variables,
      workspaceId:
        variables.workspaceId || currentWorkspaceProps.currentWorkspace?.id,
    },
    skip: !variables.workspaceId && !currentWorkspaceProps.currentWorkspace?.id,
    ...rest,
  })

  return {
    ...queryProps,
    shopifyAppConfig: _.get(queryProps, 'data.shopifyAppConfig', null),
    loading: _.some([queryProps, currentWorkspaceProps], 'loading'),
    error: _.some([queryProps, currentWorkspaceProps], 'error'),
  }
}
