import Link from 'next/link'
import { Box } from '~components/Box'

export const Item = ({
  href,
  borderBottom = 'separator',
  ...rest
}) => (
  <Box
    borderBottom={borderBottom}
  >
    <Box
      component={Link}
      href={href}
    >
      <a>
        <Box
          display='flex'
          alignItems='center'
          cursor='pointer'
          hoverBackgroundColor='light-darken5'
          marginY={1}
          padding={1}
          borderRadius='rounded'
        >
          <Box
            textStyle='text'
            color='dark'
            {...rest}
          />
        </Box>
      </a>
    </Box>
  </Box>
)
