import _ from 'lodash'
import { useQuery } from '@atlasmic/shared/hooks/misc/useQuery'
import { useCurrentWorkspace } from '@atlasmic/shared/hooks/workspaces/useCurrentWorkspace'
import { DomainsQuery } from '@atlasmic/shared/graphql/queries/domains/DomainsQuery'

export const useDomains = ({ variables, ...rest } = { variables: {} }) => {
  const { currentWorkspace, ...currentWorkspaceProps } = useCurrentWorkspace()

  const domainsProps = useQuery(DomainsQuery, {
    variables: {
      workspaceId: currentWorkspace?.id,
      ...variables,
    },
    skip: !currentWorkspace?.id,
    ...rest,
  })

  return {
    ...domainsProps,
    domains: _.map(_.get(domainsProps, 'data.domains.edges', []), 'node'),
    loading: _.some([domainsProps, currentWorkspaceProps], 'loading'),
    error: _.some([domainsProps, currentWorkspaceProps], 'error'),
  }
}
