import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Box } from '~components/Box'
import { Icon } from '~components/Icon'

export const Item = ({
  icon,
  activeIcon,
  href,
  match = href,
  type,
  backgroundColor = 'none',
  activeBackgroundColor = 'primary',
  hoverBackgroundColor = 'light-primary-darken5',
  activeHoverBackgroundColor = 'primary-darken5',
  fill = 'dark',
  activeFill = 'light',
  color = 'dark',
  activeColor = 'light',
  ...rest
}) => {
  const { asPath } = useRouter()

  const isActive = asPath.match(match)

  return (
    <Box component={Link} href={href}>
      <a>
        <Box
          display='flex'
          alignItems='center'
          padding={1}
          marginY={0.5}
          backgroundColor={isActive ? activeBackgroundColor : backgroundColor}
          hoverBackgroundColor={
            isActive ? activeHoverBackgroundColor : hoverBackgroundColor
          }
          borderRadius='rounded'
          cursor='pointer'
        >
          <Box height={3}>
            <Icon
              component={isActive ? activeIcon : icon}
              fill={isActive ? activeFill : fill}
            />
          </Box>
          {type !== 'minimized' && (
            <Box
              textStyle='text'
              color={isActive ? activeColor : color}
              marginLeft={2}
              {...rest}
            />
          )}
        </Box>
      </a>
    </Box>
  )
}
