import { Skeleton } from '@atlasmic/shared/components/skeletons/misc/Skeleton'
import { WorkspaceAvatar } from '~components/workspaces/WorkspaceAvatar'
import { WorkspaceBase } from '~components/workspaces/WorkspaceBase'

export const WorkspaceSkeleton = (props) => (
  <WorkspaceBase>
    <WorkspaceAvatar
      height={4}
      width={4}
    />
    <Skeleton
      textStyle='widget-text'
      marginLeft={1}
    />
  </WorkspaceBase>
)
