import { Box } from '~components/Box'

export const MobileOnboardingBase = ({
  illustration,
  title,
  variant = 'light',
  ...rest
}) => (
  <Box
    minHeight='100vh'
    minWidth='100wv'
    backgroundColor={variant === 'light' ? 'light' : 'dark-primary'}
    paddingTop={6}
    paddingBottom={16}
    paddingX={2}
    display='flex'
    justifyContent='center'
  >
    <Box
      maxWidth={35}
      display='flex'
      flexDirection='column'
      alignItems='center'
    >
      <Box component={illustration} maxWidth='100%' height={20} />

      <Box paddingTop={4}>
        <Box
          component='h2'
          textStyle='h3'
          color={variant === 'light' ? 'dark' : 'light'}
          textAlign='center'
        >
          {title}
        </Box>

        <Box paddingTop={1} {...rest} />
      </Box>
    </Box>
  </Box>
)
