import { gql } from '@apollo/client'
import { PlanFragment } from '~graphql/fragments/plans/PlanFragment'

export const PlanQuery = gql`
  query PlanQuery($planId: ID, $planSlug: String) {
    plan(planId: $planId, planSlug: $planSlug) {
      ...PlanFragment
    }
  }

  ${PlanFragment}
`
