import { gql } from '@apollo/client'
import { WorkspaceSubscriptionFragment } from '~graphql/fragments/workspaceSubscriptions/WorkspaceSubscriptionFragment'

export const WorkspaceSubscriptionUpdatedSubscription = gql`
  subscription WorkspaceSubscriptionUpdatedSubscription($workspaceId: ID!) {
    workspaceSubscriptionUpdated(workspaceId: $workspaceId) {
      workspaceSubscription {
        ...WorkspaceSubscriptionFragment
      }
    }
  }

  ${WorkspaceSubscriptionFragment}
`
