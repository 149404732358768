import { Button } from '@atlasmic/shared/components/buttons/Button'
import { Skeleton } from '@atlasmic/shared/components/skeletons/misc/Skeleton'

export const ButtonSkeleton = (props) => (
  <Button
    backgroundColor='light-gray'
    {...props}
  >
    <Skeleton textStyle='button' width={10} />
  </Button>
)
