import { upsertApp } from '~utils/apps/upsertApp'

export const update = (cache, {
  data: {
    upsertApp: {
      app,
    },
  },
}) => (
  upsertApp({
    client: cache,
    app,
  })
)
