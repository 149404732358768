import { useRef } from 'react'
import { Block } from '@atlasmic/shared/components/blocks/Block'
import { useModal } from '~hooks/modals/useModal'
import { Box } from '~components/Box'

export const Modal = ({ maxWidth = 58, ...rest }) => {
  const ref = useRef(null)
  const { setModal } = useModal()

  return (
    <Box
      onClick={({ target }) => {
        if (ref.current.contains(target)) return

        setModal(null)
      }}
      backgroundColor='dark-transparentize80'
      position='fixed'
      top={0}
      right={0}
      bottom={0}
      left={0}
      display={{ xs: 'none', md: 'flex' }}
      justifyContent='center'
      alignItems='center'
    >
      <Block ref={ref} maxWidth={maxWidth} {...rest} />
    </Box>
  )
}
