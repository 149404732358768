import { Box } from '~components/Box'

export const ModalHeader = (props) => (
  <Box
    display='flex'
    justifyContent='center'
    paddingTop={2}
    {...props}
  />
)
