import { Box } from '~components/Box'

export const ModalButtonsContainer = (props) => (
  <Box
    display='flex'
    justifyContent='center'
    alignItems='center'
    {...props}
  />
)
