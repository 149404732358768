import { useEffect } from 'react'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { useWorkspaces } from '@atlasmic/shared/hooks/workspaces/useWorkspaces'
import { useCurrentAgent } from '@atlasmic/shared/hooks/agents/useCurrentAgent'
import { useCurrentWorkspaceId } from '~hooks/workspaces/useCurrentWorkspaceId'

export const useEnsureCurrentWorkspace = () => {
  const { currentWorkspaceId, setCurrentWorkspaceId } = useCurrentWorkspaceId()
  const { currentAgent } = useCurrentAgent()
  const router = useRouter()
  const workspacesProps = useWorkspaces()

  useEffect(() => {
    if (!currentAgent) return
    if (workspacesProps.loading) return
    if (workspacesProps.error) return

    if (_.isEmpty(workspacesProps.workspaces)) {
      router.push(`/workspaces/create`)
      return
    }

    if (!_.some(workspacesProps.workspaces, { id: currentWorkspaceId })) {
      const firstWorkspace = _.first(workspacesProps.workspaces)
      setCurrentWorkspaceId(firstWorkspace.id)
    }
  }, [
    currentAgent,
    workspacesProps,
    router,
    currentWorkspaceId,
    setCurrentWorkspaceId,
  ])

  return null
}
