import { query } from './query'
import { data } from './data'

export const upsertApp = ({
  client,
  app,
}) => {
  let prev

  try {
    prev = client.readQuery(query({ workspaceId: app.workspaceId, type: app.type }))
  } catch (e) {}

  client.writeQuery({
    ...query({ workspaceId: app.workspaceId, type: app.type }),
    data: data({
      prev,
      app,
    }),
  })
}
