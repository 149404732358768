import { gql } from '@apollo/client'

export const ShopifyScriptTagsQuery = gql`
  query ShopifyScriptTagsQuery($workspaceId: ID!) {
    shopifyScriptTags(workspaceId: $workspaceId) {
      __typename

      ... on ShopifyScriptTagConnection {
        edges {
          node {
            src
          }
        }
      }
    }
  }
`
