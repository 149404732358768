import { sale, saleFilled } from '@atlasmic/icons'
import { useToast } from '@atlasmic/shared/hooks/toasts/useToast'
import { useCurrentPlan } from '~hooks/plans/useCurrentPlan'
import { onError } from '~utils/errors/onError'
import { Group } from '../Group'
import { Item } from '../Item'

export const UpgradeGroup = ({ type }) => {
  const { addToast } = useToast()
  const { currentPlan, loading, error } = useCurrentPlan({
    onError: onError({ addToast }),
  })

  if (loading) return null
  if (error) return null
  if (!currentPlan) return null
  if (currentPlan.slug !== 'basic') return null

  return (
    <Group>
      <Item
        href='/upgrade'
        icon={sale}
        activeIcon={saleFilled}
        type={type}
        color='money'
        fill='money'
        hoverBackgroundColor='money-transparentize90'
        activeBackgroundColor='money'
        activeHoverBackgroundColor='money-darken5'
      >
        Try Starter for Free
      </Item>
    </Group>
  )
}
