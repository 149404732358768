import { gql } from '@apollo/client'
import { AppFragment } from '@atlasmic/shared/graphql/fragments/apps/AppFragment'

export const UpsertAppMutation = gql`
  mutation UpsertAppMutation($workspaceId: ID!, $type: String!, $input: UpsertAppInput!) {
    upsertApp(workspaceId: $workspaceId, type: $type, input: $input) {
      app {
        ...AppFragment
      }
    }
  }

  ${AppFragment}
`
